import utils from '../../../utils'
import Sdk from '../../../sdk'
import Communication from '../../../communication/communication'
import conf from './assets_config'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'
import BasicModule from '../../../basic-module'

let instance = null // init the instance.
let observable = null // init the observable.
/**
 * Assets class responsible of all assets functionality and manage the state of user assets.
 * 
 * Part of the {@link Gaming} module.
 * This is a class reference, find the tutorial here: {@link Tutorial_Assets}
 * @category Gaming
 */
class Assets extends BasicModule implements Initiable<Assets> {
	loaded: boolean
	config: any
	/** Expose the assets state. */
	state: Rxjs_subject<any>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|Object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.assets, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.assets] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.assets, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!observable || config.test) {
			observable = new Rxjs_subject<any>()
			this.state = observable
		} else {
			this.state = observable
		}

		const isSdkReady = sdk.isReady()
		isSdkReady
			.then(() => this.get())
			.then((assets) => {
				this.setConfig({assets})
				this.state.next(assets)
			})
			.then(() => this.loaded = true)

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.assets.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.assets.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Get user assets together with player amount.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {String} type - Asset type(Accept: `trophy` or `shop_item`).
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<Array<Asset>>} asset settings with user amount.
	 * @example
	 * captain.assets.get()
	 * .then(assets => {
	 * 	// Get app assets data with player amount.
	 * })
	 * @example
	 * captain.assets.get(undefined, 'shop_item')
	 * .then(assets => {
	 * 	// Get only shop items data with player amount.
	 * })
	 * @example
	 * captain.assets.get(playerId)
	 * .then(assets => {
	 * 	// Get app assets data with specific player amount.
	 * })
	 */
	get(userId?, type?, sdk = new Sdk) {
		// Check if userId and sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'type', type: ['String', 'Undefined'], val: type},
			{name: 'sdk', type: 'Object', val: sdk},
		])

		const assetsData = [this.getProgress(userId), this.getList()]
		const attachAmountToAsset = ([acquired_assets = [], assets = []]) =>
			assets.map(asset => {
				const {amount} = acquired_assets.find(acquired_asset => acquired_asset.id === asset._id) || {amount: 0}
				return Object.assign({}, asset, {amount})
			})
		const filterByType = assets => assets.filter(isTypeEquals)
		const isTypeEquals = asset => type ? asset.type === type : true

		// Adds an indication to asset whether is available to player or not
		const isSegmentedToPlayer = assets => assets.map(asset => ({
			...asset,
			is_segmented_for_user: !sdk.config.player.unavailable_segmented_assets.includes(asset.id)
		}))

		return Promise.all(assetsData)
			.then(attachAmountToAsset)
			.then(isSegmentedToPlayer)
			.then(filterByType)
			.then(this.claimableRewards)
	}

	/**
	 * Adds to rewards indication of whether they are claimable or not.
	 * @version 1.0.0
	 * @private
	 * @param {Array<Assets>} assets - Asset setting list.
	 * @returns {Array<Assets>} Asset list.
	 */
	claimableRewards(assets = [], sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'sdk', type: 'Object', val: sdk},
		])
		
		// Checks if the reward is claimable or not.
		// If user.available_rewards contains reward id then it's claimable. 
		const isClaimable = reward => {
			const available_rewards = sdk.user.getConfig().available_rewards
			if (_.get(available_rewards, 'length', 0)) {
				const available_reward = _.find(available_rewards, {id: reward._id})
				// timestamps is an array of datetime string when reward was awarded and only of non claimed reward
				if (_.get(available_reward, 'timestamps.length', 0)) {
					return true
				}
			}
			return false
		}

		// Adds `claimable` property to each reward.
		const addClaimableProp = reward => {
			return {...reward, claimable: isClaimable(reward) }
		}

		// Iterate over each asset and each of its rewards and add `claimable` property.
		return assets
			.map(asset => ({
				...asset,
				rewards: [
					...(asset.rewards || []).map(addClaimableProp)
				]
			}))
	}

	/**
	 * Get all assets settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} type - Asset type.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<Trophy|ShopItem>>} List of assets settings.
	 * @example
	 * captain.assets.getList()
	 * .then(assets => {
	 * 	// Get app all assets settings.
	 * })
	 * @example
	 * captain.assets.getList('shop_item')
	 * .then(assets => {
	 * 	// Get app all shop items settings.
	 * })
	 */
	getList(type?, sdk = new Sdk) {
		// Check if the sdk is valid.
		utils.validateDependencies([
			{name: 'type', type: ['String', 'Undefined'], val: type},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		const filterByType = assets => assets.filter(isTypeEquals)
		const isTypeEquals = asset => type ? asset.type === type : true
		const getAssets = res => res.assets || []

		return sdk.init.getSDKConfig()
			.then(getAssets)
			.then(filterByType)
	}

	/**
	 * Get all user assets progress.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array<AssetProgress>>} List of assets progress.
	 * @example
	 * captain.assets.getProgress()
	 * .then(progress => {
	 * 	// Get player acquired assets.
	 * })
	 * @example
	 * captain.assets.getProgress(playerId)
	 * .then(progress => {
	 * 	// Get specific player acquired assets.
	 * })
	 */
	getProgress(userId, sdk = new Sdk) {
		// Check if the sdk is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		const getAcquiredAssets = res => res.acquired_assets

		return sdk.user.get(userId)
			.then(getAcquiredAssets)
	}

	/**
	 * Get specific asset settings.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String=} userId - User id.
	 * @param {String} assetId - Id of specific asset.
	 * @returns {Promise<Asset>} asset settings.
	 * @example
	 * captain.assets.getAsset(undefined, assetId)
	 * .then(asset => {
	 * 	// Get app specific asset settings with player amount.
	 * })
	 * @example
	 * captain.assets.getAsset(playerId, assetId)
	 * .then(asset => {
	 * 	// Get app specific asset settings with specific player amount.
	 * })
	 */
	getAsset(userId, assetId) {
		if(_.isObject(assetId)) {
			//@ts-ignore
			assetId = assetId.id
		}
		// Check if the assetId is valid.
		utils.validateDependencies([
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'assetId', type: 'String', val: assetId}
		])
		const findAssetById = (asset) => asset._id === assetId
		const findInAssets = (assets) => assets.find(findAssetById)

		return this.get(userId)
			.then(findInAssets)
	}

	/**
	 * Update user assets state.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {String} assetId - Asset id.
	 * @returns {Promise<Asset>} asset settings.
	 */
	update(assetId) {
		// Check if the assetsUpdate is valid.
		utils.validateDependencies([
			{name: 'assetId', type: 'String', val: assetId}
		])

		return this.getAsset(undefined, assetId)
			.then((asset) => {
				this.state.next(asset)
				return asset
			})
	}

	/**
	 * Acquire assets.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} assetId - Asset id.
	 * @param {Object} dynamicOptionsForReward - Dynamic Option for Reward.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication= new Communication] - Communication instance.
	 * @returns {Promise<Asset>} Acquire response.
	 * @example
	 * captain.assets.acquire(assetId).then(() => {
	 * 	// The asset was acquired.
	 * })
	 */
	acquire(assetId, dynamicOptionsForReward={}, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'assetId', type: 'String', val: assetId},
			{name: 'dynamicOptionsForReward', type: 'Object', val: dynamicOptionsForReward},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/rewards/acquire/app/${sdk.config.id}/assets/${assetId}`
		const params =  Object.keys(dynamicOptionsForReward).length  > 0 ? {
			dynamic_options_for_reward: dynamicOptionsForReward
		} : {}
		const config = {
			requestType: 'http',
			method: 'post',
			// We don't want to accidently spend too much coins for a user,
			// so we block retries for the acquire request
			retries: 0
		} as const

		return communication.request(url, params, config)
			.then((res) => {
				if( res?.stock !== undefined ) {
					const newStock = res.stock ?? 0
					const targetAsset = sdk.config.assets.find(asset => asset.id === assetId)
					if (targetAsset) {
						targetAsset.stock = newStock
					}
				}
				this.update(assetId)
			})
	}

	/**
	 * Claim asset reward.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} assetId - Asset ID.
	 * @param {String} rewardId - Reward ID.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Object>} Reward data.
	 */
	claim(assetId, rewardId, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'assetId', type: ['Object', 'String'], val: assetId},
			{name: 'rewardId', type: 'String', val: rewardId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])
		
		const updateAvailableRewards = (reward) => {
			sdk.user.setConfig({available_rewards: reward.available_rewards})
			return reward
		}
		// User may can pass asset object or asset id
		const asset_id = _.isString(assetId) ? assetId : assetId.id

		const params = {
			app_id: sdk.config.id,
			asset_id: asset_id,
			reward_id: rewardId,
			object_identifier: rewardId,
			object: 'reward'
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const
		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/app/${sdk.config.id}/assets/${params.asset_id}/rewards/${params.reward_id}/claim`
		return communication.request(url, params, config)
			.then((reward) => updateAvailableRewards(reward))
	}
	
	/**
	 * Get full assets acquisitions history data.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Array|String} assetsId - Assets ID.
	 * @param {Number=} limit - Max number of history rows.
	 * @param {Number=} skip -  Amount of rows to skip from zero.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Array<AssetHistory>>} assets acquisitions data.
	 */
	history(assetsId, limit, skip, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'assetsId', type: ['Array', 'String'], val: assetsId},
			{name: 'limit', type: ['Number', "Undefined"], val: limit},
			{name: 'skip', type: ['Number', "Undefined"], val: skip},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])

		const params = {
			app: sdk.config.id,
			assets: assetsId,
			limit,
			skip
		}

		const config = {
			requestType: 'http',
			method: 'get'
		} as const

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/players/${sdk.config.player.id}/assets/history`
		return communication.request(url, params, config)
	}
}

export default Assets
