import conf from './activities_config'
import Notification from '../notification'
import utils from '../../../utils'
import Sdk from '../../../sdk'
import {Subject as Rxjs_subject} from 'rxjs'
import Communication from '../../../communication/communication'
import * as _ from 'lodash'
import { get_mustache_context, render_template } from '../../../lib/render_template_string'
import {version} from '../../../../package.json'

let instance = null // init the instance.
let observable = null // init the observable.
let connectionObservable = null // init the connection observable.
/**
 * Activities module manage the notification type activity in the SDK, he responsible
 * to update the SDK model about new activities the user get like points and badges.
 * @category Notification
 */
class Activities implements Initiable<Activities> {
	loaded: boolean
	config: any
	/** Expose the activities state. */
	state: Rxjs_subject<any>
	/** Expose the state of the Websocket connection. */
	connection: Rxjs_subject<any>
	socket: any
	socketClosedBySDK?: boolean
	tried_to_reconnect_in_the_last_10_seconds?: boolean
	tried_to_reconnect_in_the_last_10_seconds__settimeout_for_reset?: any

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.activities, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.badges] - Defaults object.
	 * @param {Object} [props = conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.activities, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {
		}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!observable || !connectionObservable || config.test) {
			observable = new Rxjs_subject<any>()
			connectionObservable = new Rxjs_subject<any>()
			this.state = observable
			this.connection = connectionObservable
		} else {
			this.state = observable
			this.connection = connectionObservable
		}

		sdk.isReady().then(() => {
			this.socket = this.setSocketCallbacks()
		}).then(() => this.loaded = true)

		return this
	}

	/**
	 * Set up socket callbacks
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Function} [listen = this.listen.bind(this)] - Initialize activities socket channel.
	 * @param {Function} [processActivities = this.processActivities.bind(this)] - Process activities sockets data.
	 * @param {Object} [communication = new Communication] - Communication module.
	 */
	setSocketCallbacks(listen = this.listen.bind(this), processActivities = this.processActivities.bind(this), communication = new Communication) {
		// Check if the listen, processActivities and communication is valid.
		utils.validateDependencies([
			{name: 'listen', type: 'Function', val: listen},
			{name: 'processActivities', type: 'Function', val: processActivities},
			{name: 'communication', type: 'Object', val: communication},
		])

		let is_reconnecting = false
		const reconnect = () => {
			if (!is_reconnecting) {
				is_reconnecting = true

				this.tried_to_reconnect_in_the_last_10_seconds = true
				if (this.tried_to_reconnect_in_the_last_10_seconds__settimeout_for_reset) {
					clearTimeout(this.tried_to_reconnect_in_the_last_10_seconds__settimeout_for_reset)
				}
				this.tried_to_reconnect_in_the_last_10_seconds__settimeout_for_reset = setTimeout(() => this.tried_to_reconnect_in_the_last_10_seconds = false, 10000)

				setTimeout(() => {
					this.socket = this.setSocketCallbacks()
					is_reconnecting = false
				}, this.tried_to_reconnect_in_the_last_10_seconds ? 8000 : 3000)
			}
		}

		let heartBeat
		const socket = listen()
		socket.onmessage = (message) => {
			try {
				if (message.data.startsWith('uid:')) {
					// do nothing, this is the process letting us know there is a new
					// underlying RabbitMQ channel uid (plus retry count).
				} else {
					processActivities(JSON.parse(message.data))
				}
			} catch(err) {
				throw new Error('Connection Error: It seems we have problem with the server response')
			}
		}
		socket.onopen = (event) => {
			// Push the connection object so consumers can catch this event.
			this.connection.next(socket)
			// Initial heartbeat operation.
			heartBeat = communication.heartBeat(event.target)
		}
		socket.onerror = (err) => {
			// Push the connection object so consumers can catch this event.
			this.connection.next(socket)
			// If the heart beat operation exist, stop it.
			if(heartBeat)
				heartBeat()
			reconnect()
			console.warn(version, "cptup socket err: ", err)
		}
		socket.onclose = () => {
			// Push the connection object so consumers can catch this event.
			this.connection.next(socket)
			// If the heart beat operation exist, stop it.
			if (heartBeat)
				heartBeat()

			// if socket is closed by the SDK we should return and do nothing further
			if (this.socketClosedBySDK === true) {
				this.socketClosedBySDK = false
				return
			}

			reconnect()
			console.warn(version, "cptup: notifications websockets closed, reconnecting")
		}
		return socket
	}

	/**
	 * If socket connection is open stop socket and set flag so socket onClose event does not throw any error.
	 * @version 1.0.0
	 */
	closeSocketConnection() {
		if (!this.socket) { return }
		if (this.socket.readyState === this.socket.OPEN) {
			this.socketClosedBySDK = true
			this.socket.close()
			return
		}
	}

	/**
	 * Start sync process to get activities notifications from server.
	 * @version 1.0.0
	 * @private
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns Socket connection.
	 */
	listen(sdk = new Sdk, communication = new Communication): WebSocket {
		// Check if the sdk and communication is valid.
		utils.validateDependencies([
			{name: 'communication', type: 'Object', val: communication},
			{name: 'sdk', type: 'Object', val: sdk},
		])

		const url = `${sdk.config.socketDomain}/mechanics/${communication.config.api_version}/notifications/activities`
		const config = {
			requestType: 'socket',
			method: 'open'
		}  as const
		const params = {
			user: sdk.config.player.id,
			app: sdk.config.id
		}
		const cb = res => res
		//@ts-ignore
		return communication.request(url, params, config, cb)
	}

	/**
	 * Get new `activities` notifications.
	 * @version 1.0.0
	 * @async
	 * @private
	 * @param {Object} [notification = new Notification] - Notification instance.
	 * @returns {Promise<Array>} The new activities or empty array in case there is no new activities.
	 * @example
	 * captain.activities.get()
	 * .then(activities => {
	 * // Player newest activities.
	 * })
	 */
	get(notification = new Notification) {
		// Check if the notification, user and trigger is valid.
		utils.validateDependencies([
			{name: 'notification', type: 'Object', val: notification}
		])

		// return notification.get_notifications_data({type: 'activities'}) - This fails!
		return notification.get_notifications_data()
	}

	/**
	 * Process new `activities` notifications and trigger an event to notify that new notifications
	 * is in @player.unread_activities
	 * @version 1.0.0
	 * @async
	 * @private
	 * @param {Array} [activities = new Activities] - Activities list.
	 * @param {Object} [notification = new Notification] - Notification instance.
	 * @param user - User config.
	 * @param trigger - Implementation in the embed of event dispatcher.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Promise<Array>} The new activities or empty array in case there is no new activities.
	 */
	processActivities(activities, notification = new Notification, user = (utils.get_environment_global_var())['captain'].player || (utils.get_environment_global_var())['captain'].config.player, trigger = (utils.get_environment_global_var())['captain'].trigger || utils.trigger, sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'activities', type: 'Object', val: activities},
			{name: 'notification', type: 'Object', val: notification},
			{name: 'user', type: 'Object', val: user},
			{name: 'trigger', type: 'Function', val: trigger},
			{name: 'sdk', type: 'Object', val: sdk},
		])

		const badges = activities.badges.map((badge) => ({name: 'achieve', entity: badge, currencies: badge.currencies}))
		const levels = activities.levels.map((level) => ({name: this.getLevelActivityName(level, sdk.config.player.level.number), entity: level, currencies: level.currencies}))
		const tier = (activities.tier) ? {name: this.getTierActivityName(activities.tier, sdk.config.player.current_tier_index), entity: activities.tier, currencies: activities.tier.currencies} : {}
		const currencies = {name: 'currencies', currencies: activities.currencies}
		const concatActivities = [].concat([activities.action, currencies], badges, levels, tier)

		// Updating the global player details
		this.updateSDKPlayerFromActivities(activities)

		// Render templates in all app items
		// -----------------------------------
		// render badges, levels, assets, tournaments
		for (let item_type of ['badges', 'levels', 'assets', 'tournaments']) {
			for (let item of (activities[item_type] || [])) {
				let mustache_context = get_mustache_context(user, item_type, item)

				for (let attr_to_render of ['description', 'description_html', 'name']) {
					item[attr_to_render] = render_template(item[attr_to_render], mustache_context)
				}
			}
		}

		if (concatActivities && concatActivities.length > 0) {
			user.unread_activities = concatActivities
			// We take the new `badge_progress` from the `sdk.config.player` object because it has all the
			// badges in it. The `activities` notification only include those who changed in the last request,
			// that's why we don't use it here. The `sdk.config.player` is getting updates through the
			// `this.updateSDKPlayerFromActivities(activities)` method above
			user.badge_progress = sdk.config.player.badge_progress
			// Update the state with new activities.
			this.state.next(concatActivities)
			// Check if activities include level update and badge update.
			const activitiesIncludeLevelAndBadge = this.isIncludeLevelAndBadge(activities)
			// Process the new activities
			notification.notify_activity(activities, activitiesIncludeLevelAndBadge)
			// Tell the UI that we have new inbox notification using event.
			trigger('notifications:activities:new')
			return activities
		}
		return []
	}

	/**
	 * Get tier activity name
	 * @private
	 * @param {Object} activitiesTier - Incoming activities tier.
	 * @param {Number} currentTierIndex - Sdk player currrent_tier_index.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {String} - return tier_activity_name.
	 */
	getTierActivityName(activitiesTier, currentTierIndex, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesTier', type: 'Object', val: activitiesTier},
			{name: 'currentTierIndex', type: 'Number', val: currentTierIndex},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		// Check if users new tier is same or not based on that return the tier progression direction
		const sortedTiers = sdk.config.tiers.sort((a, b) => a.currency - b.currency) || []
		const activitiesTierIndex = sortedTiers.findIndex(tier => tier._id === activitiesTier._id)
		if (currentTierIndex !== activitiesTierIndex) {
			return (currentTierIndex < activitiesTierIndex)? 'tier_up' : 'tier_down'
		}
		return 'tier_kept'
	}

	/**
	 * Get level activity name
	 * @private
	 * @param {Object} activitiesLevel - Incoming activities level.
	 * @param {Number} currentLevelNumber - Sdk player current level number.
	 * @returns {String} - return level_activity_name.
	 */
	getLevelActivityName(activitiesLevel, currentLevelNumber){
		utils.validateDependencies([
			{name: 'activitiesLevel', type: 'Object', val: activitiesLevel},
			{name: 'currentLevelNumber', type: 'Number', val: currentLevelNumber}
		])
		
		// Check if users new level is same or not based on that return the level progression direction
		if (currentLevelNumber < activitiesLevel.number) {
			return 'level_up'
		}
		return 'level_down'
	}

	/**
	 * Update sdk.config.player from incoming activities
	 * @private
	 * @param {Object} activities - Incoming activities.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} - Updated sdk.config.player data.
	 */
	updateSDKPlayerFromActivities(activities, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activities', type: 'Object', val: activities},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let sdkConfigPlayer = sdk.config.player
		// Update player badge progress
		this.updatePlayerBadgeProgress(activities.badge_progress)

		// Update player available_rewards
		this.updatePlayerAvailableRewards(activities.available_rewards)

		// Update player currencies and currencies_spent
		this.updatePlayerCurrency(activities.currencies)

		// Update player achieved_badges
		this.updatePlayerAchievedBadges(activities.badges)

		// Update player acquired_assets
		this.updatePlayerAcquiredAssets(activities.acquired_assets, activities.action.timestamp)

		// Update player leaderboard points and positions
		this.updatePlayerPointsAndPositions(activities.leaderboard)

		// Update player level if activities levels has data
		this.updatePlayerlevel(activities.levels)

		// Update player action_counter
		this.updatePlayerActionCounter(activities.action)

		// Updatet player current_tier_index and tier_cycle_start_date if activities has tier
		if (activities.tier) {
			this.updatePlayerCurrentTierIndex(activities.tier)
			sdk.config.player.tier_cycle_start_date = activities.action.timestamp
		}
		return sdkConfigPlayer
	}

	/**
	 * Update player badge progress
	 * @private
	 * @param {Object} activitiesBadgeProgress - Incoming activities badge_progress.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} - Updated player badge_progress.
	 */
	updatePlayerBadgeProgress(activitiesBadgeProgress, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesBadgeProgress', type: 'Object', val: activitiesBadgeProgress},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		let sdkPlayerBadgeProgress = sdk.config.player.badge_progress
		sdk.config.player.badge_progress = _.merge(sdkPlayerBadgeProgress, activitiesBadgeProgress)
		return sdk.config.player.badge_progress
	}

	/**
	 * Update player available_rewards
	 * @private
	 * @param {Object} activitiesBadgeProgress - Incoming activities available_rewards.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} - Updated player available_rewards.
	 */
	updatePlayerAvailableRewards(activitiesAvailableRewards, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesAvailableRewards', type: 'Object', val: activitiesAvailableRewards},
			{name: 'sdk', type: 'Object', val: sdk}
		])

		let sdkPlayerAvailableRewards = sdk.config.player.available_rewards || []
		sdk.config.player.available_rewards = _.merge(sdkPlayerAvailableRewards, activitiesAvailableRewards)
		return sdk.config.player.available_rewards
	}

	/**
	 * Update sdk.config.player currencies and currency_spent
	 * @private
	 * @param {Object} activitiesCurrencies Incoming activities currencies.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} updated activityCurrencies and currencySpent.
	 */
	updatePlayerCurrency(activitiesCurrencies, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesCurrencies', type: 'Object', val: activitiesCurrencies},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let activityCurrencies = {}
		let sdkPlayerCurrencies = sdk.config.player.currencies
		let currencySpent = sdk.config.player.currency_spent
		_.map(activitiesCurrencies, (currencyData, key) => {
			if (_.has(currencyData, 'player_total')) {
				const sdkValue = sdkPlayerCurrencies[key] || 0
				const playerTotal = currencyData.player_total
				const difference = sdkValue - playerTotal
	
				if (difference > 0) {
					const playerSpent = currencySpent[key] || 0
					currencySpent[key] = playerSpent + difference
				}
				activityCurrencies[key] = playerTotal
			}
		})
		sdk.config.player.currencies = activityCurrencies
		sdk.config.player.currency_spent = currencySpent
		return { activityCurrencies, currencySpent }
	}
	
	/**
	 * Update sdk.config.player acquired_assets
	 * @private
	 * @param {Object} activitiesAcquiredAssets - Incoming activities acquired_assets.
	 * @param {String} activitiesActionTimestamp - Incoming activities action timestamp.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} updated sdkPlayerAcquiredAssets.
	 */
	updatePlayerAcquiredAssets(activitiesAcquiredAssets, activitiesActionTimestamp, sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'activitiesAcquiredAssets', type: 'Object', val: activitiesAcquiredAssets},
			{name: 'activitiesActionTimestamp', type: 'String', val: activitiesActionTimestamp},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		// Update sdk player acquired_assets from activities acquired_assets
		let sdkPlayerAcquiredAssets = sdk.config.player.acquired_assets
		if (activitiesAcquiredAssets.length > 0) {
			let idExist = false
			sdkPlayerAcquiredAssets.forEach(item => {
				// Check if the ID exists in player acquired_assets
				const idExists = activitiesAcquiredAssets.includes(item.id)
				// If the ID exists, increment the amount by 1
				if (idExists) {
					// Directly modify the item's amount without returning
					item.amount += 1
					item.last = activitiesActionTimestamp
					idExist = true
				}
			})
			if (!idExist) {
				// Create a new object with the required properties
				const newObject = {
					id: activitiesAcquiredAssets[0],
					amount: 1,
					last: activitiesActionTimestamp
				}
				// Append the new object to sdk player acquired_assets
				sdkPlayerAcquiredAssets.push(newObject)
			}
		}
		return sdkPlayerAcquiredAssets
	}

	/**
	 * Update sdk.config.player achieved_badges
	 * @private
	 * @param {Object} activitiesBadges - Incoming activities badges.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} updated sdkPlayerAchievedBadges.
	 */
	updatePlayerAchievedBadges(activitiesBadges, sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'activitiesBadges', type: 'Object', val: activitiesBadges},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		// Update player achieved_badges from activities badges
		let sdkPlayerAchievedBadges = sdk.config.player.achieved_badges
		if (activitiesBadges.length > 0) {
			activitiesBadges.forEach(badge => {
				let idExist = false
				// Check if the ID exists in player acquired_badges
				sdkPlayerAchievedBadges.forEach(item => {
					if (badge.id === item.id) {
						item.times_completed += 1
						item.achievements_dates.last = badge.updated_at
						idExist = true
					}
				})
				if (!idExist) {
					// Create a new object for new acquired_badges
					const dates = {
						first: badge.updated_at,
						last: badge.updated_at
					}
					const newObject = {
						id: badge.id,
						times_completed: 1,
						achievements_dates: dates
					}
					// Append the new object to player.achieved_badges
					sdkPlayerAchievedBadges.push(newObject)
				}
			})
		}
		return sdkPlayerAchievedBadges
	}

	/**
	 * Update sdk.config.player points and positions
	 * @private
	 * @param {Object} activitiesLeaderboard - Incoming activities leaderboard.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} updated player leaderboard points and positions.
	 */
	updatePlayerPointsAndPositions(activitiesLeaderboard, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesLeaderboard', type: 'Object', val: activitiesLeaderboard},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let sdkConfigPlayer = sdk.config.player
		sdkConfigPlayer.monthly_points = activitiesLeaderboard.monthly_points
		sdkConfigPlayer.weekly_points = activitiesLeaderboard.weekly_points
		sdkConfigPlayer.daily_points = activitiesLeaderboard.daily_points
		sdkConfigPlayer.daily_position = activitiesLeaderboard.daily_position
		sdkConfigPlayer.monthly_position = activitiesLeaderboard.monthly_position
		sdkConfigPlayer.weekly_position = activitiesLeaderboard.weekly_position
		sdkConfigPlayer.all_time_position = activitiesLeaderboard.all_time_position

		const updatedValues = {
			all_time_position: sdkConfigPlayer.all_time_position,
			daily_points: sdkConfigPlayer.daily_points,
			daily_position: sdkConfigPlayer.daily_position,
			monthly_points: sdkConfigPlayer.monthly_points,
			monthly_position: sdkConfigPlayer.monthly_position,
			weekly_points: sdkConfigPlayer.weekly_points,
			weekly_position: sdkConfigPlayer.weekly_position
		}
		return updatedValues
	}

	/**
	 * Update sdk.config.player level
	 * @private
	 * @param {Object} activitiesLevel - Incoming activities levels.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} Sdk player updated level.
	 */
	updatePlayerlevel(activitiesLevel, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesLevel', type: 'Object', val: activitiesLevel},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let sdkConfigPlayerLevel = sdk.config.player.level
		if (activitiesLevel.length > 0) {
			sdkConfigPlayerLevel = activitiesLevel[0]
			sdk.config.player.level = sdkConfigPlayerLevel
		}
		return sdkConfigPlayerLevel
	}

	/**
	 * Update sdk.config.player current_tier_index
	 * @private
	 * @param {Object} activitiesTier - Incoming activities tier.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} Sdk player updated current_tier_index.
	 */
	updatePlayerCurrentTierIndex(activitiesTier, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesTier', type: 'Object', val: activitiesTier},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let sdkConfigPlayerCurrentTierIndex = sdk.config.player.current_tier_index
		if (activitiesTier && sdk.config.tiers.length > 0) {
			const tiers = sdk.config.tiers.sort((a, b) => a.currency - b.currency) || []
			const tier_index = tiers.findIndex(tier => tier.id === activitiesTier.id)
			sdk.config.player.current_tier_index = tier_index
		}
		return sdkConfigPlayerCurrentTierIndex
	}

	/**
	 * Update sdk.config.player action_counter
	 * @private
	 * @param activitiesAction - Incoming activities action
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} 
	 */
	updatePlayerActionCounter(activitiesAction, sdk = new Sdk){
		utils.validateDependencies([
			{name: 'activitiesAction', type: 'Object', val: activitiesAction},
			{name: 'sdk', type: 'Object', val: sdk},
		])
		let sdkPlayerActionCounter = sdk.config.player.action_counter 
		for (const key in sdkPlayerActionCounter) {
			if (key.startsWith('total_' + activitiesAction.name)) {
				sdkPlayerActionCounter[key] += 1
			}
		}
		return sdkPlayerActionCounter
	}
	/**
	 * Check if activities list include badges and levels.
	 * @version 1.0.0
	 * @private
	 * @param {Object} activities - Incoming activities.
	 * @returns {Boolean} If the activities include badges and levels.
	 */
	isIncludeLevelAndBadge(activities) {
		// Check if the activities is valid.
		utils.validateDependencies([
			{name: 'activities', type: 'Object', val: activities},
		])

		const badges = !_.isEmpty(activities.badges)
		const levels = !_.isEmpty(activities.levels)
		return (levels && badges)
	}

	/**
	 * Get list of activities.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Number} [limit = 5] - Limit the length of data to fetch.
	 * @param {Number} [skip = 0] - Skip numbers of rows for start(0).
	 * @param {String=} userId - Player ID.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Object>} Server response for list of activities.
	 * @example
	 * captain.activities.getList()
	 * .then(activities => {
	 * // 5 last activities items
	 * })
	 * @example
	 * captain.activities.getList(10, 5)
	 * .then(activities => {
	 * // 10 next activities items(skip first 5 items)
	 * })
	 */
	getList(limit = 5, skip = 0, userId, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
			{name: 'userId', type: ['String', 'Undefined'], val: userId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])

		if(_.isUndefined(userId))
			userId = sdk.config.player.id

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/actions_feeds`
		const params: any = {
			limit,
			skip,
			app: sdk.config.id,
			player_id: userId
		}

		const config = {
			requestType: 'http',
			method: 'get'
		}  as const
		const cb = (x) => x
		return communication.request(url, params, config, cb)
	}
}

export default Activities
